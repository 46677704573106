.main-section{
  margin: 40px 0;
}
.laoading{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255 , 0.9);
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 100%;
}
  .selected__images{
    width: 300px;
    margin-bottom: 10px;
  }
  label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted black;
    border-radius: 20px;
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    font-size: large;
  }
  
  label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  
  input {
    display: none;
  }
  
  img {
    padding: 0;
    margin: 0;
  }
  
  .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
     list-style: none;
     text-decoration: none;
  }
  .container{
    max-width: 1240px;
    padding: 0 30px;
    margin: 0 auto;
  }
  .image {
    margin: 1rem 0.5rem;
    position: relative;
  }
  .image{
    width: 220px;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgba(43, 42, 42, 0.05);
  }
  .img__title{
    /* font-size: 16px; */
    width: 170px;
    margin: 6px  0;
    --tw-text-opacity:1;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis!important;
  }
  .delete__btn{
     display: block;
     width: 100px;

    cursor: pointer;
    border: none;
    outline: none;
    color: #fff;
    padding: 4px 6px;
    background-color: red;
    border-radius: 2px;
  }
  .save__btn{
    cursor: pointer;
    border: none;
    outline: none;
    color: #fff;
    padding: 4px 6px;
    background-color: green;
    border-radius: 2px;
  }
  .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 2.5rem;
    color: white;
    background-color: green;
  }
  

  
  .error {
    text-align: center;
  }
  
  .error span {
    color: red;
  }
  
  .uploadimg__wrapper{
    margin: 0 auto;
    max-width: 700px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    gap: 30px;
  }
  .selected__imges{
    margin-bottom: 10px;
    padding: 5px 8px;
    border-radius: 4px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid ;
  }
  .uploadimg__wrapper{
    padding: 24px;
  border-radius: 15px;
  border: 2px dotted  ;

  }

  @media only screen and (max-width:640px){
    
   .uploadimg__wrapper{
        flex-direction: column;
      }
   .selected__imges{
        width: 100%;
      }
  }

  @media only screen and (max-width:400px){
    .container{
      padding: 0 10px;
    }
  }