.modal {
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    border-radius: 20px;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .modalimg{
    width: 80%;
    height: 500px;
    /* object-fit: cover; */
   object-fit: contain;
  }

  @media only screen and (max-width:500px){
    .modalimg{
        width: 90%;
    }
  }